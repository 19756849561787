import React, { useState, useEffect } from 'react';
import crossoff from './crossoff.svg'
import crosson from './crosson.svg'
import checkon from './checkon.svg'
import checkoff from './checkoff.svg'
import checknull from './checknull.svg'
import crossnull from './crossnull.svg'
import { Oval } from  'react-loader-spinner'

function MyTable() {
  const [promoItems, setPromoItems] = useState({ list: [] });
  const [loading, setLoading] = useState(false);
  const [inputPage, setInputPage] = useState(1);
  const [totalModels, setTotalModels] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // No longer need these states since we'll open videos in a new tab
  // const [ view, setView ] = useState(false);
  // const [ viewContent, setViewContent ] = useState("");
  // const [ viewType, setViewType ] = useState("");
  const [ page, setPage ] = useState(1);

  const fetchData = async (pageNum) => {
    try {
      setLoading(true);
  
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: pageNum,
          page_size: 5,
          filter: 0,
        }),
      };
  
      const response = await fetch('https://golive.mobi/api/promo/list/', requestOptions);
  
      if (response.ok) {
        const responseData = await response.json();
        setPromoItems(responseData);
        setTotalModels(responseData.total || 0);
        setTotalPages(responseData.total_pages || 0);
        setInputPage(pageNum);
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request failed:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData(page);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const pageToFetch = parseInt(inputPage) || 1;
      fetchData(pageToFetch);
      setPage(pageToFetch);
    }
  };

  const handleNextPage = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) {
      fetchData(nextPage);
      setPage(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      const prevPage = page - 1;
      fetchData(prevPage);
      setPage(prevPage);
    }
  };

  const divHeadStyle = {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
    marginBottom: '2%',
    width: '97%',
    marginLeft: 'auto', marginRight: 'auto', display: 'block'
  };

  const tableHeaderStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '25%',
  };

  const tableHeader2Style = {
    color: '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '25%'
  };

  const avatarStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '5%'
  }

  const coverStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    width: '11.5%'  
  }

  const publicStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    width: '70%' 
  }

  const actionsStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }

  const privateStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }

  const sendVerificationRequest = async (id, url, type, file_type, success, imageIndex) => {
    const apiUrl = 'https://golive.mobi/api/verif/complete/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        url: url,
        type: type,
        file_type: file_type,
        success: success,
        web: 1
      }),
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        fetchData(page);
      } else {
        console.error('Error sending verification request:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending verification request:', error);
    }
  };

  async function sendMarkRequest(id) {
    const request = {
      id: id,
      approved: 1,
    };
  
    try {
      const response = await fetch('https://golive.mobi/api/verif/mark/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      
      if (response.ok) {
        fetchData(page);
      } else {
        console.error('Mark request failed');
      }
    } catch (error) {
      console.error('Mark request failed:', error);
    }
  }

  return (
    <>
      {loading && (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          position: 'absolute',
          left: 'auto',
          right: 'auto',
          background: 'white',
          width: '100%',
          height: '100%',
          zIndex: 1000
        }}>
          <Oval
            height={200}
            width={200}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#87CEFA"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {/* Modal view removed as we now open videos in a new tab */}

      {!loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <button 
            className="remove-button" 
            style={{ 
              backgroundColor: page <= 1 ? '#cccccc' : '#6D33FF',
              cursor: page <= 1 ? 'not-allowed' : 'pointer'
            }}  
            onClick={handlePreviousPage}
            disabled={page <= 1}
          >
            &lt; Previous
          </button>
          <input
            className="remove-button"
            style={{
              backgroundColor: '#FFF',
              width: '40px',
              color: 'black',
              textAlign: 'center',
              margin: '0 5px'
            }}
            type="number"
            value={inputPage}
            onChange={(e) => setInputPage(parseInt(e.target.value) || 1)}
            onKeyPress={handleKeyPress}
            min="1"
            max={totalPages}
          />
          <span style={{ margin: '0 5px', lineHeight: '30px', display: 'inline-block' }}>of {totalPages}</span>
          <button 
            className="remove-button" 
            style={{ 
              backgroundColor: page >= totalPages ? '#cccccc' : '#6D33FF',
              cursor: page >= totalPages ? 'not-allowed' : 'pointer'
            }} 
            onClick={handleNextPage}
            disabled={page >= totalPages}
          >
            Next &gt;
          </button>
        </div>
      )}
      
      <br />

      {promoItems && promoItems.list && promoItems.list.map((item, index) => ( 
        <div key={index} style={divHeadStyle}>
          <table className="table">
            <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
              <tr>
                <th style={tableHeaderStyle}>ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Gender</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableHeader2Style}>{item.id}</td>
                <td style={tableHeader2Style}>{item.name}</td>
                <td style={tableHeader2Style}>
                  {item.gender === 1 && 'Man'}
                  {item.gender === 2 && 'Woman'}
                  {item.gender === 3 && 'Non-binary'}
                </td>
                <td style={tableHeader2Style}>{item.countryCode}</td>
                <td style={tableHeader2Style}>
                  {item.approved === null
                    ? 'Waiting'
                    : item.approved === 0
                    ? 'Rejected'
                    : item.approved === 1
                    ? 'Approved'
                    : item.approved}
                </td>
              </tr>  
            </tbody>
          </table>

          <table className="table">
            <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
              <tr>
                <th style={avatarStyle}>Promo</th>
                <th style={avatarStyle}>Promo see live</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* PROMO */}
                <td style={coverStyle}>
                  <div style={{ display: 'flex', overflowY: 'auto' }}>
                    {(item.images || [])
                      .filter((image) => image.type === 'promo')
                      .map((image, imageIndex) => (
                        <div key={imageIndex} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%', width: '200px' }}>
                          <img
                            src={image.cover}
                            alt={`Promo Cover ${imageIndex + 1}`}
                            style={{ width: '200px', margin: '5px', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(image.url, '_blank');
                            }}
                          />

                          {image.promo_approved === null && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                              />
                              <img
                                src={crossoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                              />
                            </div>
                          )}

                          {image.promo_approved === true && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkon}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                              <img
                                src={crossoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          )}

                          {image.promo_approved === false && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                              <img
                                src={crosson}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </td>

                {/* PROMO SEE LIVE */}
                <td style={coverStyle}>
                  <div style={{ display: 'flex', overflowY: 'auto' }}>
                    {(item.images || [])
                      .filter((image) => image.type === 'promo_see_live')
                      .map((image, imageIndex) => (
                        <div key={imageIndex} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
                          <img
                            src={image.cover}
                            alt={`Promo Cover ${imageIndex + 1}`}
                            style={{ width: '200px', margin: '5px', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(image.url, '_blank');
                            }}
                          />

                          {image.promo_approved === null && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_see_live', 'video', 1)}
                              />
                              <img
                                src={crossoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_see_live', 'video', 0)}
                              />
                            </div>
                          )}

                          {image.promo_approved === true && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkon}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                              <img
                                src={crossoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_see_live', 'video', 0)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          )}

                          {image.promo_approved === false && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_see_live', 'video', 1)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                              <img
                                src={crosson}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </td>
              </tr>  
            </tbody>
          </table>

          <table className="table">
            <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
              <tr>
                <th style={avatarStyle}>Promo call me</th>
                <th style={avatarStyle}>Promo evening</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* PROMO CALL ME */}
                <td style={coverStyle}>
                  <div style={{ display: 'flex', overflowY: 'auto' }}>
                    {(item.images || [])
                      .filter((image) => image.type === 'promo_call_me')
                      .map((image, imageIndex) => (
                        <div key={imageIndex} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
                          <img
                            src={image.cover}
                            alt={`Promo Cover ${imageIndex + 1}`}
                            style={{ width: '200px', margin: '5px', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(image.url, '_blank');
                            }}
                          />

                          {image.promo_approved === null && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_call_me', 'video', 1)}
                              />
                              <img
                                src={crossoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_call_me', 'video', 0)}
                              />
                            </div>
                          )}

                          {image.promo_approved === true && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkon}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                              <img
                                src={crossoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_call_me', 'video', 0)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          )}

                          {image.promo_approved === false && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_call_me', 'video', 1)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                              <img
                                src={crosson}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </td>

                {/* PROMO EVENING */}
                <td style={coverStyle}>
                  <div style={{ display: 'flex', overflowY: 'auto' }}>
                    {(item.images || [])
                      .filter((image) => image.type === 'promo_evening_1_1')
                      .map((image, imageIndex) => (
                        <div key={imageIndex} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
                          <img
                            src={image.cover}
                            alt={`Promo Cover ${imageIndex + 1}`}
                            style={{ width: '200px', margin: '5px', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(image.url, '_blank');
                            }}
                          />

                          {image.promo_approved === null && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_evening_1_1', 'video', 1)}
                              />
                              <img
                                src={crossoff}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_evening_1_1', 'video', 0)}
                              />
                            </div>
                          )}

                          {image.promo_approved === true && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkon}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                              <img
                                src={crossoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_evening_1_1', 'video', 0)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          )}

                          {image.promo_approved === false && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={checkoff}
                                onClick={() => sendVerificationRequest(item.id, image.url, 'promo_evening_1_1', 'video', 1)}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                              <img
                                src={crosson}
                                style={{
                                  width: '30px',
                                  borderRadius: '5px',
                                  margin: '5px'
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </td>
              </tr>  
            </tbody>
          </table>
        </div>
      ))}

      {!loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <button 
            className="remove-button" 
            style={{ 
              backgroundColor: page <= 1 ? '#cccccc' : '#6D33FF',
              cursor: page <= 1 ? 'not-allowed' : 'pointer'
            }} 
            onClick={handlePreviousPage}
            disabled={page <= 1}
          >
            &lt; Previous
          </button>
          <input
            className="remove-button"
            style={{
              backgroundColor: '#FFF',
              width: '40px',
              color: 'black',
              textAlign: 'center',
              margin: '0 5px'
            }}
            type="number"
            value={inputPage}
            onChange={(e) => setInputPage(parseInt(e.target.value) || 1)}
            onKeyPress={handleKeyPress}
            min="1"
            max={totalPages}
          />
          <span style={{ margin: '0 5px', lineHeight: '30px', display: 'inline-block' }}>of {totalPages}</span>
          <button 
            className="remove-button" 
            style={{ 
              backgroundColor: page >= totalPages ? '#cccccc' : '#6D33FF',
              cursor: page >= totalPages ? 'not-allowed' : 'pointer'
            }} 
            onClick={handleNextPage}
            disabled={page >= totalPages}
          >
            Next &gt;
          </button>
        </div>
      )}
    </>
  );
}

export default MyTable;