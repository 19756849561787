import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg'
import check_disable from './check_disable.svg'
import { Oval } from  'react-loader-spinner'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cross from './cross.svg'

const TableGeneral = ({ onDateChange }) => {
  const history = useHistory();
  
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [ page, setPage ] = useState(1)
  const [ sDay, setSDAY ] = useState()
  const [ sMonth, setSMonth ] = useState()
  const [ sYear, setSYear ] = useState()
  const [ eDay, setEDAY ] = useState()
  const [ eMonth, setEMonth ] = useState()
  const [ eYear, setEYear ] = useState()
  const [ formattedStartDate, setFormattedStartDate ] = useState('')
  const [ formattedEndDate, setFormattedEndDate ] = useState('')
  const [ dateforvisualstart, setDateforvisualstart ] = useState('')
  const [ dateforvisualend, setDateforvisualend ] = useState('')
  const [ fulldate, setFulldate ] = useState('')
  const [selectedDate, setSelectedDate] = useState(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endDay, setEndDay] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endYear, setEndYear] = useState('');
  const [startDay, setStartDay] = useState('');
const [startMonth, setStartMonth] = useState('');
const [startYear, setStartYear] = useState('');

const [ modalOpen, setModalOpen ] = useState(false)

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Тестовые данные для таблицы
  const initialData = [

    // Добавьте больше тестовых данных здесь...
  ];
  
  const [loading, setLoading] = useState(true);
const [totalBalance, setTotalBalance] = useState(0);
const [totalOutgoingMessages, setTotalOutgoingMessages] = useState(0);
const [totalIncomingMessages, setTotalIncomingMessages] = useState(0);
const [totalOutgoingCalls, setTotalOutgoingCalls] = useState(0);
const [totalIncomingCallsa, setTotalIncomingCallsa] = useState(0);
const [totalIncomingCallsb, setTotalIncomingCallsb] = useState(0);
const [totalTotalTime, setTotalTotalTime] = useState(0);
const [totalRandomTime, setTotalRandomTime] = useState(0);
const [totalPrivateTime, setTotalPrivateTime] = useState(0);
const [totalTotalOnline, setTotalTotalOnline] = useState(0);
const [totalBalanceStream, setTotalBalanceStream] = useState(0);
const [totalBalanceOutside, setTotalBalanceOutside] = useState(0);

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(remainingSeconds)}`;
  return formattedTime;
}

function padWithZero(number) {
  return number.toString().padStart(2, '0');
}

const [ url, setUrl ] = useState('today')

const fetchData = useCallback(async (sDay, sMonth, sYear, eDay, eMonth, eYear, url) => {
  setLoading(true);

  const response = await fetch(`https://admin.golive.mobi/list/all`, {
    method: 'GET',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    const responseData = await response.json();
    setData(responseData);
    const totalBalance = responseData.reduce((sum, item) => sum + (item.balance ?? 0), 0);
    const totalBalanceStream = responseData.reduce((sum, item) => sum + (item.balance_inside_stream?.stream_balance ?? 0) + (item.balance_inside_stream?.gifts ?? 0), 0);
    const totalBalanceOutside = responseData.reduce((sum, item) => sum + Object.values(item.balance_outside_stream || {}).reduce((a, b) => a + b, 0), 0);
    const totalOutgoingMessages = responseData.reduce((sum, item) => sum + (item.messages?.outcoming ?? 0), 0);
    const totalIncomingMessages = responseData.reduce((sum, item) => sum + (item.messages?.incoming ?? 0), 0);
    const totalOutgoingCalls = responseData.reduce((sum, item) => sum + (item.calls?.outcoming ?? 0), 0);
    const totalIncomingCallsa = responseData.reduce((sum, item) => sum + (item.calls?.incoming_private ?? 0), 0);
    const totalIncomingCallsb = responseData.reduce((sum, item) => sum + (item.calls?.incoming_random ?? 0), 0);
    const totalTotalTime = responseData.reduce((sum, item) => sum + (item.time?.total ?? 0), 0);
    const totalRandomTime = responseData.reduce((sum, item) => sum + (item.time?.random ?? 0), 0);
    const totalPrivateTime = responseData.reduce((sum, item) => sum + (item.time?.private ?? 0), 0);
    const totalTotalOnline = responseData.reduce((sum, item) => sum + (item.time_online ?? 0), 0);
    setTotalBalance(totalBalance);
    setTotalBalanceStream(totalBalanceStream);
    setTotalBalanceOutside(totalBalanceOutside);
    setTotalOutgoingMessages(totalOutgoingMessages);
    setTotalIncomingMessages(totalIncomingMessages);
    setTotalOutgoingCalls(totalOutgoingCalls);
    setTotalIncomingCallsa(totalIncomingCallsa);
    setTotalIncomingCallsb(totalIncomingCallsb);
    setTotalTotalTime(totalTotalTime);
    setTotalRandomTime(totalRandomTime);
    setTotalPrivateTime(totalPrivateTime);
    setTotalTotalOnline(totalTotalOnline);
   // setLoading(false);
  } else {
    console.log('Error:', response.status);
  }
}, [page]);


const fetchDataToday = useCallback(async (sDay, sMonth, sYear, eDay, eMonth, eYear, url) => {
  setLoading(true);

  const response = await fetch(`https://admin.golive.mobi/list/today`, {
    method: 'GET',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    const responseData = await response.json();
    setData(responseData);
    const totalBalance = responseData.reduce((sum, item) => sum + (item.balance ?? 0), 0);
    const totalBalanceStream = responseData.reduce((sum, item) => sum + (item.balance_inside_stream?.stream_balance ?? 0) + (item.balance_inside_stream?.gifts ?? 0), 0);
    const totalBalanceOutside = responseData.reduce((sum, item) => sum + Object.values(item.balance_outside_stream || {}).reduce((a, b) => a + b, 0), 0);
    const totalOutgoingMessages = responseData.reduce((sum, item) => sum + (item.messages?.outcoming ?? 0), 0);
    const totalIncomingMessages = responseData.reduce((sum, item) => sum + (item.messages?.incoming ?? 0), 0);
    const totalOutgoingCalls = responseData.reduce((sum, item) => sum + (item.calls?.outcoming ?? 0), 0);
    const totalIncomingCallsa = responseData.reduce((sum, item) => sum + (item.calls?.incoming_private ?? 0), 0);
    const totalIncomingCallsb = responseData.reduce((sum, item) => sum + (item.calls?.incoming_random ?? 0), 0);
    const totalTotalTime = responseData.reduce((sum, item) => sum + (item.time?.total ?? 0), 0);
    const totalRandomTime = responseData.reduce((sum, item) => sum + (item.time?.random ?? 0), 0);
    const totalPrivateTime = responseData.reduce((sum, item) => sum + (item.time?.private ?? 0), 0);
    const totalTotalOnline = responseData.reduce((sum, item) => sum + (item.time_online ?? 0), 0);
    setTotalBalance(totalBalance);
    setTotalBalanceStream(totalBalanceStream);
    setTotalBalanceOutside(totalBalanceOutside);
    setTotalOutgoingMessages(totalOutgoingMessages);
    setTotalIncomingMessages(totalIncomingMessages);
    setTotalOutgoingCalls(totalOutgoingCalls);
    setTotalIncomingCallsa(totalIncomingCallsa);
    setTotalIncomingCallsb(totalIncomingCallsb);
    setTotalTotalTime(totalTotalTime);
    setTotalRandomTime(totalRandomTime);
    setTotalPrivateTime(totalPrivateTime);
    setTotalTotalOnline(totalTotalOnline);
    setLoading(false);
  } else {
    console.log('Error:', response.status);
  }
}, [page]);

  

const fetchDataYesterday = useCallback(async (sDay, sMonth, sYear, eDay, eMonth, eYear, url) => {
  setLoading(true);

  const response = await fetch(`https://admin.golive.mobi/list/yesterday`, {
    method: 'GET',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    const responseData = await response.json();
    setData(responseData);
    const totalBalance = responseData.reduce((sum, item) => sum + (item.balance ?? 0), 0);
    const totalBalanceStream = responseData.reduce((sum, item) => sum + (item.balance_inside_stream?.stream_balance ?? 0) + (item.balance_inside_stream?.gifts ?? 0), 0);
    const totalBalanceOutside = responseData.reduce((sum, item) => sum + Object.values(item.balance_outside_stream || {}).reduce((a, b) => a + b, 0), 0);
    const totalOutgoingMessages = responseData.reduce((sum, item) => sum + (item.messages?.outcoming ?? 0), 0);
    const totalIncomingMessages = responseData.reduce((sum, item) => sum + (item.messages?.incoming ?? 0), 0);
    const totalOutgoingCalls = responseData.reduce((sum, item) => sum + (item.calls?.outcoming ?? 0), 0);
    const totalIncomingCallsa = responseData.reduce((sum, item) => sum + (item.calls?.incoming_private ?? 0), 0);
    const totalIncomingCallsb = responseData.reduce((sum, item) => sum + (item.calls?.incoming_random ?? 0), 0);
    const totalTotalTime = responseData.reduce((sum, item) => sum + (item.time?.total ?? 0), 0);
    const totalRandomTime = responseData.reduce((sum, item) => sum + (item.time?.random ?? 0), 0);
    const totalPrivateTime = responseData.reduce((sum, item) => sum + (item.time?.private ?? 0), 0);
    const totalTotalOnline = responseData.reduce((sum, item) => sum + (item.time_online ?? 0), 0);
    setTotalBalance(totalBalance);
    setTotalBalanceStream(totalBalanceStream);
    setTotalBalanceOutside(totalBalanceOutside);
    setTotalOutgoingMessages(totalOutgoingMessages);
    setTotalIncomingMessages(totalIncomingMessages);
    setTotalOutgoingCalls(totalOutgoingCalls);
    setTotalIncomingCallsa(totalIncomingCallsa);
    setTotalIncomingCallsb(totalIncomingCallsb);
    setTotalTotalTime(totalTotalTime);
    setTotalRandomTime(totalRandomTime);
    setTotalPrivateTime(totalPrivateTime);
    setTotalTotalOnline(totalTotalOnline);
  //  setLoading(false);
  } else {
    console.log('Error:', response.status);
  }
}, [page]);

  
  const [data, setData] = useState(initialData);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = [];
      selectedIds.forEach((id) => {
        const item = data.find((item) => item.id === id);
        if (item) {
          requestData.push({
            id,
            signed_up: item.signed_up === 1 ? 1 : 0,
          });
        }
      });
      const response = await fetch('https://golive.mobi/api/user/m-delete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {

        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  const handleUnRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = selectedIds.map((id) => ({ id, signed_up: 1 }));
      const response = await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully undeleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const [sortedData, setSortedData] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState('');

  const sortData = (option) => {
    let sorted = [...data];
  
    if (option === 'sort1') {
      sorted = sorted.sort((a, b) => (a.messages?.outcoming > b.messages?.outcoming ? 1 : -1));
    } else if (option === 'sort2') {
      sorted = sorted.sort((a, b) => (a.messages?.incoming > b.messages?.incoming ? 1 : -1));
    } else if (option === 'sort3') {
      sorted = sorted.sort((a, b) => (a.calls?.outcoming > b.calls?.outcoming ? 1 : -1));
    } else if (option === 'sort4a') {
      sorted = sorted.sort((a, b) => (a.calls?.incoming_private > b.calls?.incoming_private ? 1 : -1));
    } else if (option === 'sort4b') {
      sorted = sorted.sort((a, b) => (a.calls?.incoming_random > b.calls?.incoming_random ? 1 : -1));
    } else if (option === 'sortf4') {
      sorted = sorted.sort((a, b) => (a.balance || 0) - (b.balance || 0));
    } else if (option === 'sort5') {
      sorted = sorted.sort((a, b) => (a.time?.total > b.time?.total ? 1 : -1));
    } else if (option === 'sort6') {
      sorted = sorted.sort((a, b) => (a.time?.random > b.time?.random ? 1 : -1));
    } else if (option === 'sort7') {
      sorted = sorted.sort((a, b) => (a.time?.private > b.time?.private ? 1 : -1));
    } else if (option === 'sort8') {
      sorted = sorted.sort((a, b) => (a.time_online > b.time_online ? 1 : -1));
    } else if (option === 'sortLastActivity') {
      sorted = sorted.sort((a, b) => {
        const lastActivityA = a.activity?.last_active ?? 0;
        const lastActivityB = b.activity?.last_active ?? 0;
        return lastActivityA - lastActivityB; // Change this line to sort from newest to oldest
      });
    }

    // Custom sorting to move bot 0 to the top, bot 2 to the bottom, and bot 1 to the end
    sorted = sorted.sort((a, b) => {
      if (a.bot === 0) return -1;
      if (a.bot === 2) return 1;
      if (a.bot === 1 && b.bot !== 0 && b.bot !== 2) return 1;
      return 0; // Включите эту строку, чтобы сортировать ботов между собой
    });
  
    setSortedData(sorted);
  };
  
  
  useEffect(() => {
    if (selectedSortOption !== '') {
      sortData(selectedSortOption);
    } else {
      setSortedData(data); // Show unsorted data when no sorting option is selected
    }
  }, [selectedSortOption, data]);
  

  const [sortOptions, setSortOptions] = useState({
    sort1: false,
    sort2: false,
    sort3: false,
    sort4a: false,
    sort4b: false,
    sortf4: false,
    sort5: false,
    sort6: false,
    sort7: false,
    sort8: false,
    sortLastActivity: false,
    noSorting: true, // New option to handle "No sorting"
  });
  
  const handleSortOptionChange = (option) => {
    setSortOptions((prevOptions) => ({
      sort1: option === 'sort1' ? !prevOptions.sort1 : false,
      sort2: option === 'sort2' ? !prevOptions.sort2 : false,
      sort3: option === 'sort3' ? !prevOptions.sort3 : false,
      sortf4: option === 'sortf4' ? !prevOptions.sortf4 : false,
      sort4a: option === 'sort4a' ? !prevOptions.sort4a : false,
      sort4b: option === 'sort4b' ? !prevOptions.sort4b : false,
      sort5: option === 'sort5' ? !prevOptions.sort5 : false,
      sort6: option === 'sort6' ? !prevOptions.sort6 : false,
      sort7: option === 'sort7' ? !prevOptions.sort7 : false,
      sort8: option === 'sort8' ? !prevOptions.sort8 : false,
      sortLastActivity: option === 'sortLastActivity' ? !prevOptions.sortLastActivity : false,
      noSorting: option === 'noSorting' ? !prevOptions.noSorting : false,
    }));
  };
  
  useEffect(() => {
    const selectedOption = Object.keys(sortOptions).find((option) => sortOptions[option]);
    setSelectedSortOption(selectedOption);
  }, [sortOptions]);

  const handleSortOptionClick = (option) => {
    handleSortOptionChange(option);
  };


  const handleF_All = () => {
    setUrl('all')
    fetchData(url);
  };

  const handleF_Today = () => {
    setUrl('today')
    fetchDataToday(url);
  };

  const handleF_Yesterday = () => {
    setUrl('yesterday')
    fetchDataYesterday(url);
  };

  useEffect(() => {
    fetchDataToday();
  }, [fetchDataToday]);

  const averageTime = totalIncomingCallsb > 0 ? formatTime(totalRandomTime / totalIncomingCallsb) : "0:00:00";

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    const dateObj = new Date(timestamp * 1000); // Convert to milliseconds
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return (
    <div>
<div style={{ marginTop: '1%', marginLeft: '1.5%'}}>

<span style={{ fontWeight: '600' }}>Filter: </span>

      <button className="create-button" style={{ width: '100px', backgroundColor: url == 'all' && '#5b748b' }} onClick={handleF_All}>
        All time
      </button>

      <button className="create-button" style={{ width: '100px', backgroundColor: url == 'today' && '#5b748b' }} onClick={handleF_Today}>
        Today
      </button>

      <button className="create-button" style={{ width: '100px', backgroundColor: url == 'yesterday' && '#5b748b' }} onClick={handleF_Yesterday}>
        Yesterday
      </button>
      </div>

<br />
      <div>
        <button className="create-button" onClick={handleClick}>
          + New Models
        </button>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{marginLeft: '1%'}}></h3>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleRemove}>Block</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleUnRemove}>Unblock</button>
          </div>
        </div>
    
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

    {!loading && (

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>

              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Name</th>
             <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sortLastActivity')}>Last activity {sortOptions.sortLastActivity ? '▼' : ''}</th>
            {/*   <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Last activity {sortOptions.sortLastActivity ? '▼' : ''}</th> */}
             <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)' }} onClick={() => handleSortOptionClick('sortf4')}>Balance {sortOptions.sortf4 ? '▼' : ''}</th> 
             {/*  <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)' }} >Balance {sortOptions.sortf4 ? '▼' : ''}</th>*/}

              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>Balance from stream</th>
<th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>Balance from calls, chat and gift</th>


              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Average response time</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Time until first response</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Average talk time </th>
             <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort1')}>Outgoing messages {sortOptions.sort1 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort2')}>Incoming messages {sortOptions.sort2 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort3')}>Outgoing calls {sortOptions.sort3 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort4a')}>Incoming private calls {sortOptions.sort4a ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort4b')}>Incoming random calls {sortOptions.sort4b ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort5')}>Total time {sortOptions.sort5 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort6')}>Random time {sortOptions.sort6 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort7')}>Private time {sortOptions.sort7 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort8')}>Time online {sortOptions.sort8 ? '▼' : ''}</th> 
              

              {/* <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Outgoing messages {sortOptions.sort1 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Incoming messages {sortOptions.sort2 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Outgoing calls {sortOptions.sort3 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Incoming private calls {sortOptions.sort4a ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Incoming random calls {sortOptions.sort4b ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Total time {sortOptions.sort5 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Random time {sortOptions.sort6 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Private time {sortOptions.sort7 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Time online {sortOptions.sort8 ? '▼' : ''}</th>*/}
              

            </tr>
          </thead>

          <tbody>
  <tr style={{ background: '#CCCCFF', borderTop: '1px solid white'}}>
    <td style={{
  
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>Total</td>
    <td>{/* Empty cell for ID column, as it doesn't have a sum */}</td>
    <td>{/* Empty cell for Name column, as it doesn't have a sum */}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalBalance}</td>

<td style={{
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
}}>{totalBalanceStream}</td>
<td style={{
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
}}>{totalBalanceOutside}</td>


<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingCalls}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingCallsa}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalIncomingCallsb}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalTotalTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalRandomTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalPrivateTime)}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{formatTime(totalTotalOnline)}</td>
    {/* Add other cells for other columns' totals if needed */}
  </tr>
</tbody>

          <tbody>
          {sortedData
    .filter((item) => item.signed_up === 1) // Filter the data based on signed_up === 1
    .map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                      
                    }}
                  />
                </td>

                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  
                  <a style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} href={`profile_model/${item.id}`}>{item.id}  {item.bot === 1 ? '🤖 ' : ''} {item.bot === 2 ? '💼 ' : ''}
                  </a>
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  <a href={`profile_model/${item.id}`} style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} >
                  {item.name} 
                  </a>
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  
                  {formatDate(item.activity.last_active ?? 0)}
                  
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.balance} 
                  
                  </td>

                  <td style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
  {item.balance_inside_stream ? Object.values(item.balance_inside_stream).reduce((a, b) => a + b, 0) : 0}
</td>
<td style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
  {item.balance_outside_stream ? Object.values(item.balance_outside_stream).reduce((a, b) => a + b, 0) : 0}
</td>


                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.avg_answer_time || 0} 
                  
                  </td>

                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.first_response || 0} 
                  
                  </td>

                  <td style={{
  color: item.selected ? '#6D33FF' : 'black',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
}}>
  {item.calls?.incoming_random > 0
    ? formatTime(Math.floor(item.time?.random / item.calls?.incoming_random))
    : "0:00:00"}
</td>


                  <td  style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.messages?.outcoming ?? 0}
</td>

<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.messages?.incoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.calls?.outcoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.calls?.incoming_private ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.calls?.incoming_random ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatTime(item.time?.total ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatTime(item.time?.random ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>
  {formatTime(item.time?.private ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>
  {formatTime(item.time_online ?? 0)}
</td>


  
              </tr>

              
            ))}
          </tbody>
          
          <tfoot>
          <tr style={{ background: '#CCCCFF', borderTop: '1px solid white'}}>
    <td style={{
  
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>Total</td>
    <td>{/* Empty cell for ID column, as it doesn't have a sum */}</td>
    <td>{/* Empty cell for Name column, as it doesn't have a sum */}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalBalance}</td>

<td style={{
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
}}>{totalBalanceStream}</td>
<td style={{
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
}}>{totalBalanceOutside}</td>


<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

<td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}></td>

    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingCalls}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingCallsa}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalIncomingCallsb}</td>



    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalTotalTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalRandomTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalPrivateTime)}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{formatTime(totalTotalOnline)}</td>
    {/* Add other cells for other columns' totals if needed */}
  </tr>
</tfoot>

        </table>

    )}



      </div>
    </div>
  );
};

export default TableGeneral;
