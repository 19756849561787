import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

/* ===== Стили ===== */

const Container = styled.div`
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: left;
  overflow-x: auto;
`;

const Title = styled.h1`
  text-align: left;
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const DatePicker = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ResetButton = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
`;

const HR = styled.hr`
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
`;

const ModeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ModeLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const ModeToggleButton = styled.button`
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
`;

const CountersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const EventCounters = styled.div`
  font-weight: bold;
`;

const ParamCounters = styled.div`
  font-weight: bold;
  text-align: right;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  background-color: #333;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-all;
`;

const ExpandableContent = styled.div`
  overflow: hidden;
  white-space: ${props => (props.expanded ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
`;

const ToggleButton = styled.button`
  margin-top: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: left;
  margin: 20px 0;
`;

/* ===== Компонент ParametersView ===== */
const ParametersView = ({ jsonStr, expanded }) => {
  let params = {};
  try {
    params = JSON.parse(jsonStr);
  } catch (e) {
    return <div>Неверный формат</div>;
  }

  const checkMark = (val) =>
    val && val.toString().trim() !== '' ? '✅' : '❌';

  let utmMark = '❌';
  if (params.utm && typeof params.utm === 'object' && Object.keys(params.utm).length > 0) {
    const utmValues = Object.values(params.utm);
    utmMark = utmValues.some(v => v && v.toString().trim() !== '') ? '✅' : '❌';
  }

  if (!expanded) {
    return (
      <div style={{ fontSize: '14px' }}>
        <div><strong>FBClick:</strong> {checkMark(params.fbc)}</div>
        <div><strong>FBP:</strong> {checkMark(params.fbp)}</div>
        <div><strong>GClid:</strong> {checkMark(params.gclid)}</div>
        <div><strong>GBraid:</strong> {checkMark(params.gbraid)}</div>
        <div><strong>UTM:</strong> {utmMark}</div>
      </div>
    );
  }

  return (
    <pre style={{ fontSize: '14px', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
      {JSON.stringify(params, null, 2)}
    </pre>
  );
};

/* ===== Основной компонент DataW2A ===== */
const DataW2A = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [expandedCells, setExpandedCells] = useState({});
  const [uniqueMode, setUniqueMode] = useState(true);

  // Объект с описаниями таблиц
  const tableDescriptions = {
    web2app_golivemobi_android: 'сайт golive.mobi - ветка android для редиректа в Play Market (GClid)',
    web2app_golivemobi_ios: 'сайт golive.mobi - ветка iOS для редиректа в Play Market (GClid)',
    web2app_iosgolivemobi: 'сайт ios.golive.mobi (FBClick)',
    web2app_liveagencygolivemobi: 'сайт liveagency.golive.mobi (FBClick)',
  };

  // Столбцы, которые можно сворачивать/разворачивать
  const expandableColumns = ['additional_data', 'parametrs', 'full_url', 'parameters'];

  // При клике по ячейке – переключаем состояние expanded для нее
  const toggleCell = (rowIndex, colName) => {
    setExpandedCells(prev => ({
      ...prev,
      [rowIndex]: {
        ...prev[rowIndex],
        [colName]: !prev[rowIndex]?.[colName],
      },
    }));
  };

  /* ===== Загрузка списка таблиц ===== */
  useEffect(() => {
    const fetchTables = async () => {
      setLoadingTables(true);
      setError('');
      try {
        const response = await axios.get('https://ios.golive.mobi/listdbs/columns');
        const data = response.data;
        if (data.tables && Array.isArray(data.tables)) {
          setTables(data.tables);
          if (data.tables.length > 0) {
            setSelectedTable(data.tables[0]);
          }
        } else {
          throw new Error('Неверный формат ответа от сервера');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingTables(false);
      }
    };
    fetchTables();
  }, []);

  // Смена таблицы – сбрасываем фильтр по дате
  useEffect(() => {
    setSelectedDay('');
  }, [selectedTable]);

  /* ===== Загрузка данных выбранной таблицы ===== */
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedTable) return;
      setLoadingData(true);
      setError('');
      try {
        const response = await axios.get(`https://ios.golive.mobi/listdbs/data?table=${encodeURIComponent(selectedTable)}`);
        const data = response.data;
        if (data.data && Array.isArray(data.data)) {
          setTableData(data.data);
        } else {
          throw new Error('Неверный формат данных таблицы');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, [selectedTable]);

  /* ===== Фильтрация данных по дате ===== */
  const filteredData = selectedDay
    ? tableData.filter(row => row.date && row.date.startsWith(selectedDay))
    : tableData;

  /* ===== Уникальные по tracking_id ===== */
  // Если включен uniqueMode, оставляем только первую запись каждого tracking_id, иначе – все
  const uniqueEntriesByTrackingId = Array.from(
    new Map(filteredData.map(row => [row.tracking_id, row])).values()
  );
  const dataForCounters = uniqueMode ? uniqueEntriesByTrackingId : filteredData;

  /* ===== Подсчет событий ===== */
  // Для типа события eventType
  const countUniqueEvents = (eventType) => {
    // собираем unique tracking_id для конкретного события eventType
    const seenTrackingIds = new Set();
    for (const row of filteredData) {
      if (row.event === eventType && !seenTrackingIds.has(row.tracking_id)) {
        seenTrackingIds.add(row.tracking_id);
      }
    }
    return seenTrackingIds.size;
  };

  const countAllEvents = (eventType) => {
    return filteredData.filter(row => row.event === eventType).length;
  };

  const countFromwebByTrackingId = () => {
    const trackingCounts = {};
    filteredData.forEach(row => {
      if (row.event === 'fromweb' && row.tracking_id) {
        trackingCounts[row.tracking_id] = (trackingCounts[row.tracking_id] || 0) + 1;
      }
    });
    let moreThanOne = 0;
    let exactlyOne = 0;
    Object.values(trackingCounts).forEach(count => {
      if (count > 1) moreThanOne++;
      else if (count === 1) exactlyOne++;
    });
    return { moreThanOne, exactlyOne };
  };

  // Итого событий (или уникальных "строк")
  const totalEvents = uniqueMode ? uniqueEntriesByTrackingId.length : filteredData.length;
  const entranceCount = uniqueMode ? countUniqueEvents('entrance') : countAllEvents('entrance');
  const fromwebCount = uniqueMode ? countUniqueEvents('fromweb') : countAllEvents('fromweb');
  const redirectCount = uniqueMode ? countUniqueEvents('redirect') : countAllEvents('redirect');

  const { moreThanOne: multiFromwebCount, exactlyOne: singleFromwebCount } = countFromwebByTrackingId();

  /* ===== Подсчет параметров (fbc, fbp, gclid, gbraid, utm) ===== */
  const parameterTotals = dataForCounters.reduce(
    (acc, row) => {
      if (row.parameters) {
        try {
          const params = JSON.parse(row.parameters);
          if (params.fbc && params.fbc.toString().trim() !== '') acc.fbc += 1;
          if (params.fbp && params.fbp.toString().trim() !== '') acc.fbp += 1;
          if (params.gclid && params.gclid.toString().trim() !== '') acc.gclid += 1;
          if (params.gbraid && params.gbraid.toString().trim() !== '') acc.gbraid += 1;
          if (params.utm && typeof params.utm === 'object' && Object.keys(params.utm).length > 0) {
            const utmValues = Object.values(params.utm);
            if (utmValues.some(v => v && v.toString().trim() !== '')) acc.utm += 1;
          }
        } catch (e) {
          // Игнорируем ошибки парсинга
        }
      }
      return acc;
    },
    { fbc: 0, fbp: 0, gclid: 0, gbraid: 0, utm: 0 }
  );

  /* ===== Обработчики ===== */
  const toggleUniqueMode = () => {
    setUniqueMode(prev => !prev);
  };

  const handleTableChange = (e) => {
    setSelectedTable(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const resetDateFilter = () => {
    setSelectedDay('');
  };

  /* ===== Рендер ===== */
  return (
    <Container>
      <Title>Просмотр базы данных</Title>
      
      {loadingTables ? (
        <LoaderContainer>
          <Oval color="#00BFFF" height={60} width={60} />
        </LoaderContainer>
      ) : error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <>
          <Select value={selectedTable} onChange={handleTableChange}>
            {tables.map((table) => (
              <option key={table} value={table}>
                {table} {tableDescriptions[table] ? `- ${tableDescriptions[table]}` : ''}
              </option>
            ))}
          </Select>
          <HR />
          <div>
            <h3>Фильтр по дате</h3>
            <DatePicker type="date" value={selectedDay} onChange={handleDateChange} />
            <ResetButton onClick={resetDateFilter}>Сбросить фильтр</ResetButton>
          </div>

          {/* Режим показа (уникальные/все) */}
          <ModeContainer>
            <ModeLabel>Режим показа:</ModeLabel>
            <ModeToggleButton onClick={toggleUniqueMode}>
              {uniqueMode ? 'Уникальные' : 'Все'}
            </ModeToggleButton>
          </ModeContainer>

          <CountersRow>
            <EventCounters>
              <div><strong>Зашло на сайт:</strong> {entranceCount}</div>
              <div><strong>Отправлен запрос:</strong> {fromwebCount}</div>
              <div><strong>Редирект в стор:</strong> {redirectCount}</div>
              <div>
                <strong>Юзеры у которых было больше 1 запроса и редиректа:</strong> {multiFromwebCount}
              </div>
              <div>
                <strong>Юзеры у которых был 1 запрос и редиректа:</strong> {singleFromwebCount}
              </div>
            </EventCounters>
            <ParamCounters>
              <div>
                <strong>FBC:</strong> {parameterTotals.fbc} &nbsp;
                <strong>FBP:</strong> {parameterTotals.fbp} &nbsp;
                <strong>GClid:</strong> {parameterTotals.gclid} &nbsp;
                <strong>GBraid:</strong> {parameterTotals.gbraid} &nbsp;
                <strong>UTM:</strong> {parameterTotals.utm}
              </div>
            </ParamCounters>
          </CountersRow>
          <HR />
          {loadingData ? (
            <LoaderContainer>
              <Oval color="#00BFFF" height={60} width={60} />
            </LoaderContainer>
          ) : error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : filteredData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(filteredData[0]).map((col) => (
                    <th key={col}>{col}</th>
                  ))}
                </TableRow>
              </TableHead>
              <tbody>
                {filteredData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.entries(row).map(([col, value], colIndex) => {
                      if (expandableColumns.includes(col)) {
                        const expanded = expandedCells[rowIndex]?.[col] || false;
                        return (
                          <TableCell key={colIndex}>
                            {col === 'parameters' ? (
                              <>
                                <ParametersView jsonStr={value !== null ? value.toString() : ''} expanded={expanded} />
                                <ToggleButton onClick={() => toggleCell(rowIndex, col)}>
                                  {expanded ? 'Свернуть' : 'Развернуть'}
                                </ToggleButton>
                              </>
                            ) : (
                              <>
                                <ExpandableContent expanded={expanded}>
                                  {value !== null ? value.toString() : ''}
                                </ExpandableContent>
                                <ToggleButton onClick={() => toggleCell(rowIndex, col)}>
                                  {expanded ? 'Свернуть' : 'Развернуть'}
                                </ToggleButton>
                              </>
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={colIndex}>
                            {value !== null ? value.toString() : ''}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Для выбранной таблицы данных не найдено.</p>
          )}
        </>
      )}
    </Container>
  );
};

export default DataW2A;