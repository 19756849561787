import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';

const Noface = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://golive.mobi/api/banned/list/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify({ page: currentPage, page_size: 100, filter: '' })
      });
      const result = await response.json();
      const dataWithProof = await Promise.all(result.list.map(async (item) => {
        try {
          if (item.banned_reason === 'man_detected') {
            try {
              const proofResponse = await fetch(`https://streams.golive.mobi/getbans/${item.id}`);
              // Пытаемся получить JSON независимо от статуса ответа
              const proofResult = await proofResponse.json().catch(() => ({}));
              item.proofPhoto = proofResult.photo || 'N/A';
            } catch (error) {
              console.log(`Error fetching proof for ${item.id}:`, error);
              item.proofPhoto = 'N/A';
            }
          } else {
            try {
              const proofResponse = await fetch(`https://apilive.golive.mobi/search_ban?user_id=${item.id}`);
              // Пытаемся получить JSON независимо от статуса ответа
              const proofResult = await proofResponse.json().catch(() => ({}));
              item.proofPhoto = proofResult.banned_photo_url || 'N/A';
            } catch (error) {
              console.log(`Error fetching ban info for ${item.id}:`, error);
              item.proofPhoto = 'N/A';
            }
          }
        } catch (itemError) {
          console.log(`General error processing item ${item.id}:`, itemError);
          item.proofPhoto = 'N/A';
        }
        return item;
      }));
      setData(dataWithProof);
      setTotalPages(result.total_pages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate.replace(',', '');
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleUnban = async (id) => {
    try {
      await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify([{ id, signed_up: 1 }])
      });
      setCurrentPage(currentPage);
      fetchData();
    } catch (error) {
      console.error('Error unbanning user:', error);
    }
  };

  const getBannedReason = (reason) => {
    if (reason === 'random_mute') {
      return 'Out of frame';
    } else if (reason === 'random_nudity') {
      return 'Nudity';
    } else if (reason === 'rejected_web') {
      return 'Verification reject';
    } else if (reason === 'man_detected') {
      return 'Men detected on AI-Verification';
    } else {
      return reason || 'N/A';
    }
  };

  const getSourceOfBan = (reason) => {
    if (reason === 'rejected_web') {
      return 'Manual';
    } else {
      return 'Auto';
    }
  };

  const getPlacement = (reason) => {
    if (reason === 'random_mute' || reason === 'random_nudity') {
      return 'Random';
    } else if (reason === 'rejected_web') {
      return 'Admin panel';
    } else {
      return 'N/A';
    }
  };

  const handleProfileClick = (id) => {
    window.open(`https://admin.golive.mobi/profile_model/${id}`, '_blank');
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#fff',
  };

  const headerStyle = {
    backgroundColor: '#f8f9fa',
    color: '#212529',
    borderBottom: '1px solid #dee2e6',
    padding: '1rem',
    textAlign: 'left',
  };

  const rowStyle = {
    borderBottom: '1px solid #dee2e6',
  };

  const avatarStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '10%',
    marginRight: '10px',
    cursor: 'pointer',
  };

  const unbanButtonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const paginationButtonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#f8f9fa',
    color: '#212529',
    border: '1px solid #dee2e6',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
        <Oval color="#4CAF50" height={80} width={80} />
      </div>
    );
  }

  return (
    <div>
      <table style={tableStyles}>
        <thead>
          <tr>
            <th style={headerStyle}>Banned Date</th>
            <th style={headerStyle}>Avatar/Name</th>
            <th style={headerStyle}>Banned Reason</th>
            <th style={headerStyle}>Source of Ban</th>
            <th style={headerStyle}>Placement</th>
            <th style={headerStyle}>Proof</th>
            <th style={headerStyle}>Multi-account</th>
            <th style={headerStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id} style={rowStyle}>
              <td style={{ padding: '1rem' }}>{formatDate(item.banned_date) || 'N/A'}</td>
              <td style={{ padding: '1rem' }}>
                <img src={"https://golive.mobi" + item.avatar} alt={item.name} style={avatarStyle} onClick={() => handleProfileClick(item.id)} /><br />
                <span style={{ cursor: 'pointer' }} onClick={() => handleProfileClick(item.id)}>{item.name}</span>
              </td>
              <td style={{ padding: '1rem' }}>{getBannedReason(item.banned_reason)}</td>
              <td style={{ padding: '1rem' }}>{getSourceOfBan(item.banned_reason)}</td>
              <td style={{ padding: '1rem' }}>{getPlacement(item.banned_reason)}</td>
              <td style={{ padding: '1rem' }}>
  {item.proofPhoto && item.proofPhoto !== 'N/A' ? (
    <img src={item.proofPhoto} alt="Proof" style={{ width: '100px' }} />
  ) : (
    'N/A'
  )}
</td>

              <td style={{ padding: '1rem' }}>{item.multiacc === true ? 'Yes' : (item.multiacc === null ? 'No' : '')}</td>
              <td style={{ padding: '1rem' }}>
                <button style={unbanButtonStyle} onClick={() => handleUnban(item.id)}>Unban</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '15px' }}>
        {currentPage > 1 && <button style={paginationButtonStyle} onClick={handlePrevPage}>Previous</button>}
        <span style={{ margin: '0 1rem' }}>{currentPage}</span>
        {currentPage < totalPages && <button style={paginationButtonStyle} onClick={handleNextPage}>Next</button>}
      </div>
      <br /><br />
    </div>
  );
};

export default Noface;
