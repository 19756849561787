import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';

const Checkban = () => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchModels = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const [statusResponse, blockedStreamResponse] = await Promise.all([
        fetch(`https://golive.mobi/api/user/status/name/?page=${pageNum}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
          },
          body: JSON.stringify({ name, "deleted": 1 })
        }),
        fetch(`https://golive.mobi/api/user/status/name/?page=${pageNum}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
          },
          body: JSON.stringify({ name, "blocked_stream": 1 })
        })
      ]);

      const statusData = await statusResponse.json();
      const blockedStreamData = await blockedStreamResponse.json();

      const filteredStatusModels = statusData.list.filter(model => model.chat_only === 1 || model.block_status_stream === 1 && model.id.length > 25 && model.model_bot === 0);
      const filteredBlockedStreamModels = blockedStreamData.list.filter(model => model.chat_only === 1 || model.block_status_stream === 1 && model.id.length > 25 && model.model_bot === 0);
      
      // Mark models from blocked stream with nudity status
      const nudityMarkedModels = filteredBlockedStreamModels.map(model => ({ ...model, block_status: 'Nudity - Streams' }));

      // Merge models from both endpoints
      const combinedModels = [...filteredStatusModels, ...nudityMarkedModels];

      /*await fetchBanStatus(combinedModels);*/
      setPage(statusData.page);
      setTotalPages(statusData.total_pages);
    } catch (error) {
      console.error("Failed to fetch models:", error);
    }
    setIsLoading(false);
  };

  const fetchBanStatus = async (models) => {
    try {
      const updatedModels = await Promise.all(models.map(async (model) => {
        try {
          if (model.block_status === 'man_detected' || model.block_status === 'rejected_web') {
            try {
              const response = await fetch(`https://streams.golive.mobi/getbans/${model.id}`);
              // Даже если ответ не OK, всё равно пытаемся получить данные
              const data = await response.json().catch(() => ({}));
              model.bannedPhotoUrl = data.photo || '';
            } catch (err) {
              console.log(`Error fetching ban for ${model.id}, setting empty string`, err);
              model.bannedPhotoUrl = '';
            }
          } else {
            try {
              const response = await fetch(`https://apilive.golive.mobi/search_ban?user_id=${model.id}`);
              // Даже если ответ не OK, всё равно пытаемся получить данные
              const data = await response.json().catch(() => ({}));
              model.bannedPhotoUrl = data.banned_photo_url || null;
            } catch (err) {
              console.log(`Error fetching ban for ${model.id}, setting null`, err);
              model.bannedPhotoUrl = null;
            }
          }
        } catch (modelError) {
          console.log(`Error processing model ${model.id}`, modelError);
          // Не прерываем обработку при ошибке с одной моделью
        }
        return model;
      }));
      setModels(updatedModels);
      return { status: 200, models: updatedModels };
    } catch (error) {
      console.error("Failed to fetch ban status:", error);
      setModels(models);
      return { status: 200, models: models }; // Всё равно возвращаем статус 200
    }
  };

  const unblockModel = async (id, modelName) => {
    const userToken = localStorage.getItem("usertoken");
    setIsLoading(true);
    try {
      await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify([{ id, signed_up: 1 }])
      });

      await fetch('https://golive.mobi/api/stream/enable/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify({ id, value: 1 })
      });

      await fetch('https://admin.golive.mobi/unblock_check/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userToken,
          modelName,
          eventType: "unblock_moderator"
        })
      });
      
      alert('Модель успешно разблокирована.');
      fetchModels(page);
    } catch (error) {
      console.error("Failed to unblock model:", error);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
      <Oval color="#4CAF50" height={80} width={80} />
    </div>;
  }

  return (
    <div>
      <div style={{ padding: '20px' }}>
        <label htmlFor="modelName">Enter the name of the banned model:</label>
        <input
          id="modelName"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ margin: '0 10px' }}
        />
        <button onClick={() => fetchModels()} style={{ padding: '5px 15px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Search</button>
      </div>
      <div>
        {models.length > 0 ? models.map((model) => (
          <div key={model.id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', display: 'flex', alignItems: 'center' }}>
            <img src={`https://golive.mobi${model.avatar}`} alt="Model" style={{ width: '100px', height: '150px', marginRight: '20px' }} />
            <div>
              <p>Name: {model.name}</p>
              <p>Age: {model.age}</p>
              <p>Country Code: {model.countryCode}</p>
              <p>Block Status: {
                model.block_status ? (
                  model.block_status === 'rejected_web' ? "Didn't pass verification" :
                  model.block_status === 'random_nudity' ? 'Nudity - Random' :
                  model.block_status === 'random_mute' ? 'Out of frame' :
                  model.block_status === 'man_detected' ? 'Men detected on AI-Verification' :
                  model.block_status
                ) : (
                  'Checking...'
                )
              }</p>
            </div>
            <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
              {model.bannedPhotoUrl && model.bannedPhotoUrl !== 'N/A' ? (
                <img src={model.bannedPhotoUrl} alt="Banned Proof" style={{ width: '100px', marginRight: '25px' }} />
              ) : (
                ''
              )}
              <button onClick={() => unblockModel(model.id, model.name)} style={{ padding: '5px 15px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: '5px' }}>
                Unblock
              </button>
              <br />
              <button onClick={() => window.open(`https://admin.golive.mobi/profile_model/${model.id}`, '_blank')} style={{ padding: '5px 15px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>View Profile</button>
            </div>
          </div>
        )) : <p>No models found.</p>}
      </div>
      {totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
            <button key={number} onClick={() => fetchModels(number)} style={{ margin: '0 5px', padding: '5px 10px', backgroundColor: number === page ? '#007bff' : '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              {number}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Checkban;
